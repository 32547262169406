// Toggle Dark Mode
if (localStorage.getItem('dark')) {
    document.documentElement.classList.add('dark');
} else {
    document.documentElement.classList.remove('dark');
}

const point = document.getElementById('waypoint');

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

function handleScrollPosition(direction){
    if (direction === 'down') {
        document.getElementById('header-logos').classList.remove('lg:pt-6', 'lg:pb-6');
        document.getElementById('logo-large').classList.add('hidden');
        document.getElementById('logo-small').classList.remove('hidden');
    } else {
        document.getElementById('header-logos').classList.add('lg:pt-6', 'lg:pb-6');
        document.getElementById('logo-large').classList.remove('hidden');
        document.getElementById('logo-small').classList.add('hidden');
    }
}
var processScroll = debounce((direction) => handleScrollPosition(direction));

window.addEventListener('load',function(){
    if (typeof (point) != 'undefined' && point != null) {
        // Nav Waypoints
        const waypoint = new Waypoint({
            element: document.getElementById('waypoint'),
            handler: function(direction){
                processScroll(direction);  
            },
        })
    }
});

const textSizes = ['text-sm', 'text-base', 'text-lg'];

const increase = document.getElementById('increase-text');
window.addEventListener('load',function(){
    if (typeof (increase) != 'undefined' && increase != null) {
        increase.addEventListener('click', function (e) {
            e.preventDefault();
            const elements = document.querySelectorAll('.resizeable');
            elements.forEach(element => {
                element.classList.forEach(token => {
                    if (token.startsWith('text-')) {
                        const index = textSizes.indexOf(token);
                        if (index !== -1 && index + 1 <= 2) {
                            const replacement = textSizes[index + 1];
                            element.classList.replace(token, replacement);
                        }
                    }
                })
            });
            return false;
        });
    }
});

const decrease = document.getElementById('decrease-text');
window.addEventListener('load',function(){
    if (typeof (increase) != 'undefined' && increase != null) {
        decrease.addEventListener('click', function (e) {
            e.preventDefault();
            const elements = document.querySelectorAll('.resizeable');
            elements.forEach(element => {
                element.classList.forEach(token => {
                    if (token.startsWith('text-')) {
                        const index = textSizes.indexOf(token);
                        if (index !== -1 && index - 1 >= 0) {
                            const replacement = textSizes[index - 1];
                            element.classList.replace(token, replacement);
                        }
                    }
                })
            });
            return false;
        });
    }
});

function handleMenuParentInteraction(e) {
    e.preventDefault();
    const el = e.target;
    const target = el.dataset.target;
    document.querySelectorAll('.mobile-menu-child').forEach(function (child) {
        child.classList.add('hidden');
    });
    if (el.innerHTML.trim() === '+') {
        el.innerHTML = '-';
        document.getElementById(target).classList.remove('hidden');
    } else {
        el.innerHTML = '+';
        document.getElementById(target).classList.add('hidden');
    }
}
window.addEventListener('load',function(){
    document.querySelectorAll('.mobile-menu-parent').forEach(function (item) {
        item.addEventListener('click', handleMenuParentInteraction);
        item.addEventListener('touch', handleMenuParentInteraction);
    });
});
function handleShowSocialIconInteraction(e) {
    e.preventDefault();
    const target = document.getElementById(e.target.parentNode.dataset.target) || document.getElementById(e.target.dataset.target);
    if (target.classList.contains('hidden')) {
        target.classList.remove('hidden');
    } else {
        target.classList.add('hidden');
    }
}

window.addEventListener('load',function(){
    document.querySelectorAll('.show-social-icons').forEach(function (item) {
        item.addEventListener('click', handleShowSocialIconInteraction);
        item.addEventListener('touch', handleShowSocialIconInteraction);
    });

    document.querySelectorAll('.podcast-mini-player').forEach(function (item) {
        item.addEventListener('click', handlePlayPodcastMini);
        item.addEventListener('touch', handlePlayPodcastMini);
    });
});

function handleOpenVideoDialog(e) {
    const dialog = document.getElementById('video-dialog');
    const container = document.getElementById('video-dialog-container');
    const video = e.target.parentNode.dataset.video || e.target.dataset.video;
    const url = e.target.parentNode.dataset.url || e.target.dataset.url;
    const title = e.target.parentNode.dataset.title || e.target.dataset.title;
    if (!dialog || !container || !video || !url || !title) {
        return
    }
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'video-iframe');
    iframe.setAttribute('src', video);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('referrerPolicy', 'strict-origin-when-cross-origin');
    iframe.setAttribute('allowfullscreen', 'true');
    container.appendChild(iframe);
    dialog.showModal();
    e.preventDefault();
}

function handleCloseVideoDialog(e) {
    const dialog = document.getElementById('video-dialog');
    const container = document.getElementById('video-dialog-container');
    if (!dialog || !container) {
        return
    }
    const iframe = document.getElementById('video-iframe');
    if (iframe) {
        container.removeChild(iframe);
    }
    dialog.close();
    e.preventDefault();
}

document.querySelectorAll('.video-dialog-opener').forEach(function (item) {
    item.addEventListener('click', handleOpenVideoDialog);
    item.addEventListener('touch', handleOpenVideoDialog);
});

document.querySelectorAll('.video-dialog-closer').forEach(function (item) {
    item.addEventListener('click', handleCloseVideoDialog);
    item.addEventListener('touch', handleCloseVideoDialog);
});


function handleOpenVideoDialog(e) {
    const dialog = document.getElementById('video-dialog');
    const container = document.getElementById('video-dialog-container');
    const video = e.target.parentNode.dataset.video || e.target.dataset.video;
    const url = e.target.parentNode.dataset.url || e.target.dataset.url;
    const title = e.target.parentNode.dataset.title || e.target.dataset.title;
    if (!dialog || !container || !video || !url || !title) {
        return
    }
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'video-iframe');
    iframe.setAttribute('src', video);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('referrerPolicy', 'strict-origin-when-cross-origin');
    iframe.setAttribute('allowfullscreen', 'true');
    container.appendChild(iframe);
    dialog.showModal();
    e.preventDefault();
}

function handleCloseVideoDialog(e) {
    const dialog = document.getElementById('video-dialog');
    const container = document.getElementById('video-dialog-container');
    if (!dialog || !container) {
        return
    }
    const iframe = document.getElementById('video-iframe');
    if (iframe) {
        container.removeChild(iframe);
    }
    dialog.close();
    e.preventDefault();
}

document.querySelectorAll('.video-dialog-opener').forEach(function (item) {
    item.addEventListener('click', handleOpenVideoDialog);
    item.addEventListener('touch', handleOpenVideoDialog);
});

document.querySelectorAll('.video-dialog-closer').forEach(function (item) {
    item.addEventListener('click', handleCloseVideoDialog);
    item.addEventListener('touch', handleCloseVideoDialog);
});